<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-primary-subtle">
            <div class="row">
              <div class="col-8 d-flex align-items-center">
                <div class="text-primary p-4">
                  <h5 class="text-primary"> Reset Password</h5>
                  <!-- <p>Reset Password with Skote.</p> -->
                </div>
              </div>
              <div class="col-4 align-self-end">
                <div class="d-flex justify-content-center">
                  <img src="assets/images/logo.png" alt="" class="img-fluid w-60 mt-1">
                </div>
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo-mini.png" alt="" class="rounded-circle" height="35">
                  </span>
                </div>
              </a>
            </div>

            <div class="p-2">


              <form class="needs-validation" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>
                <!-- @if(error){
                  <alert type="danger" [dismissible]="false">{{ error }}</alert>
                  } -->
                <div class="mb-3">
                  <label for="email">Enter OTP</label>

                  <input type="number" formControlName="otp" class="form-control" id="otp" placeholder="OTP" />
                  @if(submitted && f['otp'].errors){
                  <div class="invalid-feedback">
                    @if(f['otp'].errors['required']){
                    <div>{{resetPwdValidations.otp_required_validation}}</div>}
                  </div>}
                </div>

                <div class="mb-3">
                  <label for="password">New Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password" class="form-control"
                      placeholder="Enter new password">

                    <button class="btn btn-light ms-0" type="button" id="password-addon">
                      <i class="mdi mdi-eye-outline"
                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"
                        (click)="toggleFieldTextType()"></i></button>
                    @if(submitted && f['password'].errors){
                    <div class="invalid-feedback">
                      @if(f['password'].errors['required']){
                      <span>{{resetPwdValidations.newpwd_required_validation}}</span>
                      }
                    </div>}
                  </div>
                </div>

                <div class="mb-3">
                  <label for="password">Confirm Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                      formControlName="confirmPassword" placeholder="Enter confirm password">

                    <button class="btn btn-light ms-0" type="button" id="password-addon">
                      <i class="mdi mdi-eye-outline"
                        [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"
                        (click)="toggleFieldTextType()"></i></button>
                    @if(submitted && f['confirmPassword'].errors){
                    <div class="invalid-feedback">
                      @if(f['confirmPassword'].errors['required']){
                      <span>{{resetPwdValidations.confirmPwd_required_validation}}</span>
                      }
                      @if(f['confirmPassword'].value != f['password'].value){
                      <span>{{resetPwdValidations.matchPwd_validation}}</span>
                      }

                    </div>}
                    <div class="invalid-feedback">
                      <span
                        *ngIf="f['confirmPassword'].value != f['password'].value">{{resetPwdValidations.matchPwd_validation}}</span>
                    </div>

                  </div>
                </div>

                <div class="text-end">
                  <button class="btn btn-primary w-md" (click)="reset()" type="submit">Reset</button>
                </div>

                <div class="alert alert-success text-center mb-4 p-2 mt-3" *ngIf="otpSuccessMsg" role="alert">
                  {{respMessage.message}}
                </div>

                <div class="alert alert-danger text-center mb-4 p-2 mt-3" *ngIf="otpErrorMsg" role="alert">
                  {{respMessage.message}}
                </div>

              </form>
            </div>

          </div> <!-- end card-body-->
        </div>
        <!-- end card -->
        <!-- <div class="mt-5 text-center">
            <p>Remember It ? <a routerLink="/auth/login" class="fw-medium text-primary"> Sign In here</a> </p>
          </div> -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->