import { NgModule } from '@angular/core';
import { RouterModule , Routes } from '@angular/router';
import { IotLoginComponent } from './iot-login/iot-login.component';
import { IotResetpasswordComponent } from './iot-resetpassword/iot-resetpassword.component';
import { IotForgotpasswordComponent } from './iot-forgotpassword/iot-forgotpassword.component';


const routes : Routes = [

    { path: '', component: IotLoginComponent },
    { path: 'login', component: IotLoginComponent },
    { path: 'resetPassword', component: IotResetpasswordComponent },
    { path: 'forgotPassword', component: IotForgotpasswordComponent },
  
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class AuthRoutingModule {}