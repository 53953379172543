import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { Validations } from '../../../shared/constants/validations';

@Component({
  selector: 'app-iot-forgotpassword',
  templateUrl: './iot-forgotpassword.component.html',
  styleUrl: './iot-forgotpassword.component.scss',
})
export class IotForgotpasswordComponent {
  resetForm!: UntypedFormGroup;
  submitted: any = false;
  error: any = '';
  success: any = '';
  loading: any = false;

  otpSuccessMsg: boolean = false;
  otpErrorMsg: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();
  respMessage: any;
  forgotPwdValidations = Validations.ForgotPwdPage;

  // tslint:disable-next-line: max-line-length
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  sendOTP() {}

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {}

  // convenience getter for easy access to form fields
  get f() {
    return this.resetForm.controls;
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;
    this.otpSuccessMsg = false;
    this.otpErrorMsg = false;
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }
    this.loaderService.setLoading(true);
    this.authService.forgotPassword(this.f['email'].value).subscribe({
      next: (res) => {
        console.log(res);
        if (
          res.statusCode == 200 &&
          res.message == 'Password reset link sent successfully.'
        ) {
          this.otpSuccessMsg = true;
          this.router.navigate(['/auth/resetPassword'], {
            state: { email: this.f['email'].value },
          });
          this.loaderService.setLoading(false);
        } else {
          this.otpErrorMsg = true;
          this.respMessage = res;
          this.loaderService.setLoading(false);
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.log('err', err);
      },
    });
    // if (environment.defaultauth === 'firebase') {
    //   this.authenticationService.resetPassword(this.f.email.value)
    //     .catch(error => {
    //       this.error = error ? error : '';
    //     });
    // }
  }
}
