import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { Validations } from '../../../shared/constants/validations';

@Component({
  selector: 'app-iot-resetpassword',
  templateUrl: './iot-resetpassword.component.html',
  styleUrl: './iot-resetpassword.component.scss',
})
export class IotResetpasswordComponent {
  resetForm!: UntypedFormGroup;
  submitted: any = false;
  error: any = '';
  success: any = '';
  loading: any = false;

  otpSuccessMsg: boolean = false;
  otpErrorMsg: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();
  fieldTextType!: boolean;
  mailId: any;
  respMessage: any;

  resetPwdValidations = Validations.ResetPwdPage;

  // tslint:disable-next-line: max-line-length
  constructor(
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (history.state.email != undefined && history.state.email !== null) {
      this.mailId = history.state.email;
    }
  }

  reset() {}

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {}

  // convenience getter for easy access to form fields
  get f() {
    return this.resetForm.controls;
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;

    // stop here if form is invalid
    if (
      this.resetForm.invalid &&
      this.f['confirmPassword'].value != this.f['password'].value
    ) {
      return;
    }
    this.loaderService.setLoading(true);
    let payload = {
      password: this.f['password'].value,
      confirmPassword: this.f['confirmPassword'].value,
      email: this.mailId,
      otp: this.f['otp'].value,
    };
    this.authService.resetPassword(payload).subscribe({
      next: (res) => {
        console.log(res);
        if (
          res.statusCode == 200 &&
          res.message == 'Password reset successful.'
        ) {
          this.otpSuccessMsg = true;
          this.otpErrorMsg = false;
          this.respMessage = res;
          this.loaderService.setLoading(false);
          setTimeout(() => {
            this.router.navigate(['login'], { replaceUrl: true });
          }, 3000);
        } else {
          this.otpErrorMsg = true;
          this.otpSuccessMsg = false;
          this.respMessage = res;
          this.loaderService.setLoading(false);
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.log('err', err);
      },
    });
    // if (environment.defaultauth === 'firebase') {
    //   this.authenticationService.resetPassword(this.f.email.value)
    //     .catch(error => {
    //       this.error = error ? error : '';
    //     });
    // }
  }
}
