import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IotLoginComponent } from './iot-login/iot-login.component';
import { IotForgotpasswordComponent } from './iot-forgotpassword/iot-forgotpassword.component';
import { IotResetpasswordComponent } from './iot-resetpassword/iot-resetpassword.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
@NgModule({
  declarations: [
    IotLoginComponent,
    IotForgotpasswordComponent,
    IotResetpasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
  ],
})
export class AuthModule {}
